import React from 'react';
import { css, Theme, useTheme } from "@emotion/react";

import MainHeading from "core/Components/Item/MainHeading";
import Form from "elements/Components/HubspotForm/components/Form";
import Wrapper from "core/Components/Wrapper";
import SectionContainer from "core/Components/SectionContainer";

type Props = {
    data: HubspotForm
}

const HubspotForm: React.FC<Props> = ({ data }) => {
    const theme: Theme = useTheme();

    const wrapperStyle = css`
        width: 100%;
        min-height: 100%;
        padding: 10px 182px;

        ${theme.breakpoints.up("3xl")} {
            max-width: 1600px;
        }

        ${theme.breakpoints.only("xxl")} {
            padding: 10px 80px;
        }

        ${theme.breakpoints.only("xl")} {
            padding: 10px 88px;
        }

        ${theme.breakpoints.only("lg")} {
            padding: 10px 45px;
        }

        ${theme.breakpoints.only("md")} {
            padding: 10px 45px;
        }

        ${theme.breakpoints.only("sm")} {
            padding: 10px 50px 43px 10px;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 10px 20px 57px 10px;
        }
    `;

    return (
        <SectionContainer>
            <Wrapper css={wrapperStyle} useMinHeight={false}>
                <MainHeading text={data?.header} textColour={theme.colours.curiousBlue} />
                <Form hubspotFormId={data?.hubspotFormId} />
            </Wrapper>
        </SectionContainer>
    );
}

export default HubspotForm;