import React from "react";
import { css, useTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { showPreferences } from "vanilla-cookieconsent";

import FooterLink from "core/Components/Footer/FooterLink";
import { FLAG_COOKIE_SETTINGS } from "core/includes/featureFlags";
import { useAppState } from "core/contexts/AppContext";

type Props = {
    footerBottomMenu: [any] | undefined,
};

const FooterBottomSection: React.FC<Props> = ({
    footerBottomMenu,
}) => {
    const theme = useTheme();
    const [appState] = useAppState();
    const hasCookiesFeatureFlag = appState.featureFlags?.includes(FLAG_COOKIE_SETTINGS);

    const style = css`
        display: flex;
        justify-content: end;
        margin-top: 16px;
        width: 100%;
        
        ${theme.breakpoints.down("md")} {
            justify-content: start;
            flex-direction: column;
        }
    `;

    const manageCookiesStyle = css`
        cursor: pointer;
        color: ${theme.colours.grey[650]};
    `;

    return (
        <div css={style}>
            {hasCookiesFeatureFlag && (
                <div css={manageCookiesStyle} onClick={showPreferences}>
                    Manage Cookie Settings
                </div>
            )}
            {footerBottomMenu?.map((menu: StandardPage) => (
                <FooterLink key={'footer' + menu.id} isBottomMenu>
                    <NavLink to={'/' + menu.uRLSegment}>
                        {menu.titleForFooter ?? menu.title}
                    </NavLink>
                </FooterLink>
            ))}
        </div>
    );
};

export default FooterBottomSection;