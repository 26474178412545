import React, { useMemo } from "react";
import { css, useTheme } from "@emotion/react";

import NavMenuText from "core/Components/Navigations/NavMenuText";
import NavMenuTextCover from "core/Components/Navigations/NavMenuTextCover";
import NavMenuUnderLine from "core/Components/Navigations/NavMenuUnderLine";

type Props = {
    children: React.ReactNode,
    data: StandardPage,
    selectedSegments?: string[],
    mouseOverHandler: Function,
    mouseClickHandler: Function,
};

const NavTextBox: React.FC<Props> = ({
    children,
    data,
    selectedSegments = undefined,
    mouseOverHandler,
    mouseClickHandler,
}) => {
    const theme = useTheme();

    const onMouseOverHandler = () => {
        mouseOverHandler();
    };

    const onClickHandler = () => {
        if (data.isClickable) {
            mouseClickHandler();
        }
    };

    const lastSegment = useMemo(() => (
        selectedSegments?.[selectedSegments.length - 1] ?? null
    ), [selectedSegments]);

    const style = css`
        height: ${theme.sizes.menu.heightMain}px;
        
        &:hover {
            color: ${theme.colours.curiousBlue};
        }
        
        &:active {
            .nav-menu-underline {
                background-color: ${theme.colours.curiousBlue};
            }
        }

        // Selected menu
        ${lastSegment === data.uRLSegment && css`
            color: ${theme.colours.curiousBlue};
            font-weight: ${theme.fonts.weights.bold};
            
            .nav-menu-underline {
                background-color: ${theme.colours.curiousBlue};
            }
        `}
    `;

    return (
        <div css={style} onMouseOver={onMouseOverHandler} onClick={onClickHandler}>
            <NavMenuText>{children}</NavMenuText>
            <NavMenuUnderLine />
            <NavMenuTextCover />
        </div>
    );
};

export default NavTextBox;