import { useEffect } from "react";

import { HUBSPOT_PORTAL_ID } from "core/config/config";

const useHubspotIntegration = (): void => {
    useEffect(() => {
        const configureHubSpot = (): void => {
            (window as any)._hsq = (window as any)._hsq || [];

            const consentCookie = document.cookie
                .split("; ")
                .find((row) => row.startsWith("cc_cookie="));

            if (!consentCookie) return;

            const userPreferences = JSON.parse(decodeURIComponent(consentCookie.split("=")[1]));

            if (!userPreferences.categories.includes("Analytics")) {
                // Disable HubSpot analytics tracking
                (window as any)._hsq.push(["doNotTrack"]);
            }
        };

        const loadHubSpotScript = (): void => {
            if (!document.getElementById("hubspot-script")) {
                const script = document.createElement("script");
                script.src = `https://js.hs-scripts.com/${HUBSPOT_PORTAL_ID}.js`;
                script.id = "hubspot-script";
                script.async = true;
                document.body.appendChild(script);

                script.onload = () => configureHubSpot();
            } else {
                configureHubSpot();
            }
        };

        window.addEventListener("cookieConsentChange", configureHubSpot);
        loadHubSpotScript();

        return () => {
            window.removeEventListener("cookieConsentChange", configureHubSpot);
        };
    }, []);
};

export default useHubspotIntegration;
