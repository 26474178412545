import React, { useCallback, useRef, memo, useState, useEffect } from "react";
import { css, useTheme } from "@emotion/react";

import { GOOGLE_APP_ID } from "core/config/config";
import { useScript } from "core/hooks/useScript";
import { SocialLoginType } from "core/includes/auth";
import useBreakpoint from "core/hooks/useBreakpoint";
import useElementDimensions from "core/hooks/useElementDimensions";

declare global {
    interface Window {
        google?: any;
        cookieconsent: {
            showPreferences: () => void,
            rebind: any,
        }
    }
}

type Props = {
    socialSignUp: (socialUser: any, socialLoginType: SocialLoginType) => void,
    handleLoginFailure: () => void,
    buttonWidth?: number,
};

const SocialLogin: React.FC<Props> = ({
    socialSignUp,
    handleLoginFailure,
    buttonWidth = 0,
}) => {
    const [buttonChanged, setButtonChanged] = useState(false);
    const googleSignInButton = useRef(null);
    const googleButtonWidth = useElementDimensions(googleSignInButton.current).width;
    const theme = useTheme();
    const { breakpoint } = useBreakpoint();

    useEffect(() => {
        if (buttonWidth !== googleButtonWidth) {
            setButtonChanged(true);
        } else if (buttonChanged) {
            setButtonChanged(false);
        }
    }, [buttonWidth, googleButtonWidth, setButtonChanged, buttonChanged]);

    useScript("https://accounts.google.com/gsi/client", () => {
        window.google.accounts.id.initialize({
            client_id: GOOGLE_APP_ID,
            callback: handleGoogleLogin,
        });
        window.google.accounts.id.renderButton(googleSignInButton.current, {
            width: `${buttonWidth}px`,
            type: "text",
            shape: "circle",
            text: "continue_with",
            size: "large",
        });
    }, buttonChanged && breakpoint);

    const handleGoogleLogin = useCallback((socialUser: any) => {
        try {
            socialSignUp(socialUser, "google");
        } catch (e) {
            console.log(`e-->`, e);
            handleLoginFailure();
        }

    }, [socialSignUp, handleLoginFailure]);

    const socialLoginBlock = css`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        > div {
            margin-bottom: 21px;
        }

        ${theme.breakpoints.only("md")} {
            margin-top: 17px;
        }

        ${theme.breakpoints.down("xs")} {
            > div {
                margin-bottom: 20px;
            }
        }
    `;

    return (
        <div css={socialLoginBlock}>
            <div
                id={GOOGLE_APP_ID!}
                ref={googleSignInButton}
            />
        </div>
    );
};

export default memo(SocialLogin);