import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { run } from "vanilla-cookieconsent";
import { isEmpty } from "lodash";
import Cookies from "js-cookie";

import { COOKIE_CATEGORIES_QUERY } from "core/includes/queries";

const useCookieConsent = ({ disable = false } = {}) => {
    const [categoriesConsented, setCategoriesConsented] = useState<string[]>([]);
    const { data } = useQuery(COOKIE_CATEGORIES_QUERY);

    const [
        cookieCategoriesSettings,
        cookieCategoriesData,
        cookiesToDelete
    ]: [
        any,
        CookieCategoryData[],
        CookieTableRow[]
    ] = useMemo(() => {
        if (!data) return [{}, [], []];

        const cookieCategoriesSettings: any = {};
        const cookieCategoriesData = [];

        for (const cookieCat of data.cookieCategories) {
            cookieCategoriesSettings[cookieCat.name as keyof typeof data] = {
                enabled: true,
                readOnly: !cookieCat.isManageable,
            }

            cookieCategoriesData.push({
                title: cookieCat.name,
                linkedCategory: cookieCat.name,
                description: cookieCat.description || "",
                cookieTable: {
                    caption: "Cookies",
                    headers: {
                        name: "Cookie",
                        duration: "Duration",
                        domain: "Domain",
                        desc: "Description",
                    },
                    body: cookieCat.cookies.map((cookie: any) => ({
                        name: cookie.name,
                        duration: cookie.duration,
                        domain: cookie.domain,
                        desc: cookie.description,
                    }))
                }
            });
        }

        const cookiesToDelete: CookieTableRow[] = cookieCategoriesData
            .filter((cat: CookieCategoryData) => !categoriesConsented.includes(cat.title))
            .map((cat: CookieCategoryData) => cat.cookieTable.body)
            .flat();

        return [cookieCategoriesSettings, cookieCategoriesData, cookiesToDelete];
    }, [data, categoriesConsented]);

    useEffect(() => {
        if (isEmpty(cookieCategoriesData) || isEmpty(cookieCategoriesSettings) || disable) return;

        run({
            onConsent: (consentCookie: any) => setCategoriesConsented(consentCookie.cookie.categories),
            onChange: () => {
                const event = new Event("cookieConsentChange");
                window.dispatchEvent(event);
            },
            categories: cookieCategoriesSettings,
            language: {
                default: "en",
                translations: {
                    en: {
                        consentModal: {
                            title: "We use cookies",
                            description: "This website uses cookies to improve your experience. Click ‘Accept' if " +
                                "that’s okay. Alternatively, you can customise your cookie preferences or " +
                                "opt-out if you wish.",
                            acceptAllBtn: "Accept",
                            acceptNecessaryBtn: "Reject",
                            showPreferencesBtn: "Manage Cookie Settings"
                        },
                        preferencesModal: {
                            title: "Overview",
                            acceptAllBtn: "Accept",
                            acceptNecessaryBtn: "Reject",
                            savePreferencesBtn: "Accept current selection",
                            closeIconLabel: "Close modal",
                            sections: [
                                {
                                    description: "We use cookies to give you the best experience while you navigate " +
                                        "our website. The cookies categorised as 'necessary' are stored on your " +
                                        "browser, as they are essential for basic functionalities of the website." +
                                        " We also use third-party cookies that help us analyse and understand how" +
                                        "you use the website. These cookies will only be stored in your browser with" +
                                        " your consent. You also have the option to opt-out of these cookies; " +
                                        "however, opting out of some of these cookies may effect your browsing " +
                                        "experience."
                                },
                                ...cookieCategoriesData || [],
                                {
                                    title: "More information",
                                    description: 'For any queries in relation to my policy on cookies and your ' +
                                        'choices, please <a href="/about/contact-us/">contact us</a>.'
                                }
                            ]
                        }
                    }
                }
            }
        }).then(() => {
            // Dynamically apply styles to the consent modal
            const consentContainer = document.getElementById('cc-main');
            if (consentContainer) {
                consentContainer.style.setProperty('--cc-btn-primary-bg', '#1D8BDB');
                consentContainer.style.setProperty('--cc-btn-primary-border-color', '#1D8BDB');
                consentContainer.style.setProperty('--cc-btn-primary-hover-bg', '#68BAF9');
                consentContainer.style.setProperty('--cc-btn-primary-hover-border-color', '#68BAF9');
            }
        });
    }, [cookieCategoriesSettings, cookieCategoriesData, disable]);

    const deleteTargetCookies = useCallback(() => {
        cookiesToDelete.forEach((cookie: CookieTableRow) => {
            Cookies.remove(cookie.name, { path: "/", domain: cookie.domain});
        });
    }, [cookiesToDelete]);

    useEffect(() => {
        if (!cookieCategoriesData || !categoriesConsented.length || disable) return;

        deleteTargetCookies();
    }, [cookieCategoriesData, categoriesConsented, disable, deleteTargetCookies]);

    const monitorCookies = useCallback(() => {
        if (!cookieCategoriesData || !categoriesConsented.length || disable) return;
        // Delete initially
        deleteTargetCookies();

        // Monitor changes in the DOM
        const observer = new MutationObserver(() => {
            deleteTargetCookies();
        });

        observer.observe(document, { childList: true, subtree: true });
    }, [deleteTargetCookies, categoriesConsented, disable, cookieCategoriesData]);

    monitorCookies();
};

export default useCookieConsent;